<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="mortgage" backlink="/mortgage/problems" />
      <div class="content">
        <div class="content__container">
          <Question
            service="mortgage"
            name="has_delay"
            question="question_5"
            successUrl="/mortgage/about-delay"
            negativeUrl="/mortgage/something-else"
            :droppedSteps="['/mortgage/about-delay']"
            :negativeMissedSteps="['/mortgage/about-delay']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Question from '@/components/Question.vue'
export default {
  name: 'Mortgage_delay',
  components: { Backlink, Question }
}
</script>
